<template>
  <div class="body">
    <div style="padding-top: 35px">
      <p class="title">下载聚销售 APP</p>
      <p class="subtitle">车增值产品服务掌舵者 </p>
    </div>
    <div
      class="downloadBtn"
      v-if="getMobileOperatingSystem() !== 'IOS'"
      @click="downloadApp('android')"
    >
      <img src="./images/android.png" />
      <p class="subtitle">安卓版下载</p>
    </div>
    <div class="downloadBtn" v-else @click="downloadApp('ios')">
      <img src="./images/ios.png" />
      <p class="subtitle">IOS 下载</p>
    </div>

    <img src="./images/logo.png" class="logo" />
    <div class="tip" v-if="!is_weixn()">
      <img src="./images/zhiyin.png" />
    </div>
    <!-- {{  userAgent }} -->
    <van-popup round v-model="show">
      <div style="padding: 30px; width: 300px">
        <van-field
          v-model="mobile"
          label=""
          placeholder="请输入手机号获取下载链接"
        />
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding-bottom: 20px;
        "
      >
        <van-button
          round
          style="width: 200px"
          @click="downloadIOS()"
          type="info"
          >下载</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getDownloadUrl, getDownloadUrlByPhone } from "@/api/gjApp.js";
export default {
  data() {
    return {
      downloadData: {},
      show: false,
      mobile: "",
      userAgent: ""
    };
  },
  mounted() {},
  methods: {
    downloadIOS() {
      if (!this.mobile) {
        this.$toast.fail("请输入手机号");
        return;
      }
      if (!this.validatePhoneNumber(this.mobile)) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      getDownloadUrlByPhone({ mobile: this.mobile }).then((res) => {
        if (res.code == 100000005) {
          this.$toast.fail(res.msg);
        } else {
          window.location.href = res.content.redemptionLink;
        }
      });
    },
    validatePhoneNumber(phoneNumber) {
      // 使用正则表达式进行校验
      let regex = /^1[3456789]\d{9}$/;
      return regex.test(phoneNumber);
    },
    downloadApp(type) {
      if (type === "ios") {
        this.show = true;
        return;
      }
      const url = this.downloadData[type].downloadUrl;
      // console.log(url, '===========url')
      window.location.href = url;
    },
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return false;
      } else {
        return true;
      }
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log(userAgent, "userAgent");
      this.userAgent = userAgent;
      if (
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPod/i) ||
        userAgent.match(/Macintosh/i) ||
        userAgent.match(/iOS/i)
      ) {
        return "IOS";
      } else if (userAgent.match(/Android/i)) {
        return "Android";
      } else {
        return "unknown";
      }
    },
  },
  async created() {
    const res = await getDownloadUrl({ code: "jubao" });
    // console.log(res, '===========res')
    if (!res.success) return;
    this.downloadData = res.content;
  },
};
</script>

<style scpoed>
.body {
  width: 100vw;
  height: 100vh;
  background: url("./images/bg.jpg") no-repeat;
  background-size: contain;
  background-position: top;
}
.title {
  font-size: 28px;
  color: #fff;
  text-align: center;
  /* color: #3a7df6; */
  /* margin-top: 40%; */
}
.subtitle {
  font-size: 22px;
  color: #fff;
  text-align: center;
  /* margin-top: 20px; */
}
.downloadBtn {
  width: 5rem;
  height: 1.2rem;
  border-radius: 1rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3rem;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3a7df6;
  /* width: 300px;
  height: 50px;
  border-radius: 25px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3a7df6; */
}
.downloadBtn img {
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 10px;
}
.logo {
  width: 4rem;
  position: fixed;
  bottom: 0.7rem;
  left: 50%;
  transform: translateX(-50%);
}
.tip {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding-top: 80px;
}
.tip img {
  width: 100%;
  height: 240px;
}
</style>
